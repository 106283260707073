import 'index.scss'
import 'tailwind.css'
import { Application } from 'stimulus'

// Use console log to explicitely reference the images
// as otherwise they are not loaded
import images from '../images/**/*.{jpg,jpeg,png,svg}'
console.log(images.length)

// Import all javascript files from src/_components
import components from 'bridgetownComponents/**/*.{js,jsx,js.rb,css}'

window.Stimulus = Application.start()

import controllers from './controllers/**/*.{js,js.rb}'
Object.entries(controllers).forEach(([filename, controller]) => {
  if (filename.includes('_controller.')) {
    const identifier = filename
      .replace('./controllers/', '')
      .replace(/_controller..*$/, '')
      .replace('/', '--')

    Stimulus.register(identifier, controller.default)
  }
})
