import { Controller } from 'stimulus'

export default class extends Controller {
  switch() {
    const currentTheme = document.documentElement.dataset.theme
    const nextTheme = currentTheme == 'light' ? 'dark' : 'light'
    document.documentElement.dataset.theme = nextTheme
    localStorage.setItem('theme', nextTheme)
  }
}
